import React, { Component } from 'react';
const classnames = Utils.classnames;


class ButtonGroup extends Component {

  render() {
    const { className='', ...rest } = this.props;
    const classNames = classnames(`ly-btn-group ${className}`);
    return (
     <div className={classNames} {...rest} />
    )
  }
}

export default ButtonGroup;