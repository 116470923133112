import React, { Component } from 'react';
import { Icon } from 'antd';
import './index.less';

const IconFont = Loader.loadBaseComponent('IconFont');

/**
 * type: default | primary | success | none
 * size: default | large | small
 * theme: outline | fill | gradient
 * 
 * TODO: 防抖节流 及 loading状态
 */

let styleForPesudo;

const classnames = Utils.classnames;

class Button extends Component {

  componentWillUnmount() {
    if(this.clickWaveTimeoutId) {
      clearTimeout(this.clickWaveTimeoutId);
      clearTimeout(this.clearWaveTimeoutId);
    }
  }

  getAttributeName = () => {
    return 'ant-click-animating-without-extra-node';
  }

  resetEffect = () => {
    const attributeName = this.getAttributeName();
    this.btnNode.setAttribute(attributeName, 'false')
  }

  setWaveEffect = () => {
    const node = this.btnNode;
    const attributeName = this.getAttributeName();
    node.setAttribute(attributeName, 'true');
    const waveColor =
      getComputedStyle(node).getPropertyValue('border-top-color') || 
      getComputedStyle(node).getPropertyValue('border-color') ||
      getComputedStyle(node).getPropertyValue('background-color');
    styleForPesudo = styleForPesudo || document.createElement('style');
    styleForPesudo.innerHTML = `[${attributeName}="true"]:after { border-color: ${waveColor}; }`;
    if (!document.body.contains(styleForPesudo)) {
      document.body.appendChild(styleForPesudo);
    }
    this.clearWaveTimeoutId = setTimeout(() => {
      this.resetEffect();
      styleForPesudo.innerHTML=''
    }, 2000);
  }

  handleClick = (e) => {
    const { onClick } = this.props;
    if(!this.btnNode.classList.contains('ly-btn-link')) {
      this.resetEffect();
      this.clickWaveTimeoutId = setTimeout(this.setWaveEffect, 0);
    }
    onClick && onClick(e);
  }

  getTypeCls = (type) => {
    let typeCls='';
    switch (type) {
      case 'primary':
        typeCls = 'ly-btn-primary'
      break;
      case 'success':
        typeCls = 'ly-btn-success'
      break;
      case 'link':
        typeCls = 'ly-btn-link'
      break;
      default:
        break;
    }
    return typeCls;
  }

  getSizeCls = (size) => {
    let sizeCls='';
    switch (size) {
      case 'small':
        sizeCls = 'ly-btn-sm'
      break;
      case 'large':
        sizeCls = 'ly-btn-lg'
      break;
      default:
        break;
    }
    return sizeCls;
  }

  getThemeCls = (size) => {
    let themeCls='';
    switch (size) {
      case 'fill':
        themeCls = 'ly-btn-fill'
      break;
      case 'gradient':
        themeCls = 'ly-btn-gradient'
      break;
      case 'darkline':
        themeCls = 'ly-btn-darkline'
      break;
      default:
        break;
    }
    return themeCls;
  }

  render() {
    const {
      className='',
      type,
      size,
      theme,
      disabled,
      icon,
      textOnly=true,
      onClick,
      children,
      ...rest
    } = this.props;
    const typeCls = this.getTypeCls(type);
    const sizeCls = this.getSizeCls(size);
    const themeCls = this.getThemeCls(theme);
    let textCls = (!icon && type==='link' && textOnly) ? 'text-only' : '';
    const classNames = classnames(`
      ly-btn
      ${sizeCls}
      ${typeCls}
      ${textCls}
      ${themeCls}
      ${disabled ? 'disabled' : ''} 
      ${className}
    `)
    return (
      <button
        disabled={disabled}
        ref={btnNode => this.btnNode = btnNode}
        className={classNames}
        onClick={this.handleClick}
        {...rest}
      >
        { icon ? <Icon type={icon} /> : null }
        <span>{ children }</span>
      </button>
    )
  }
}

export default Button;